import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { QuerySnapshot } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { User } from 'src/app/shared/interfaces/AuthUser';
import { CollectionsService, UserService } from '../../../shared/services';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['../../../shared/assets/styles/styles.scss', './business.component.scss']
})
export class BusinessComponent implements OnInit {
  user?: any;
  userData?: User;
  allBusiness?: any[];
  potentialPot?: number = 0;
  jackPot: number = 0;

  translateStatus = {
    PENDING: 'Affaire en attente',
    ONGOING: 'Affaire en cours',
    CLOSE: 'Affaire conclue',
    NOT_CLOSE: 'Affaire non conclue',
  }

  constructor(
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private collectionsService: CollectionsService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.getUser();
  }

  getUser() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
      this.userData = user.data;
      this.getBusiness(user.uid);
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    });
  }

  getBusiness(userUid: string) {
    this.collectionsService.getCollectionByKeyValue('Business', 'depositorUid', userUid)
    .pipe(
      map(
        (business: QuerySnapshot<any>) => business.docs.map(d => {
          return Object.assign(d.data(), {uid: d.id});
        }),
        take(1)
      )
    ).subscribe(business => {
      this.allBusiness = business;
      business.filter(value => value.status === 'ONGOING').forEach(b => {
        this.potentialPot += b.amount_1;
      });
      business.filter(value => value.status === 'CLOSE').forEach(b => {
        this.jackPot += b.amount_1;
      })
    }, err => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    })
  }

  ngOnInit() {
  }


  copy(label: string) {
    this._snackBar.open(label + ' copié', null, {
      duration: 3000,
    });
  }

  modify(businessUid) {
    this.router.navigate(['/client/create-business', businessUid]);
  }

  delete(businessUid) {
    var r = confirm("Êtes vous sur de vouloir supprimer l'affaire ?");

    if(true === r) {
      this.collectionsService.deleteDoc('Business', businessUid).then(() => {
        this.getUser();
        this.cdr.markForCheck();
        this._snackBar.open('Affaire supprimée', null, {
          duration: 3000,
        });
      }).catch(() => {
        this._snackBar.open('Une erreur est survenue', null, {
          duration: 3000,
        });
      });
    }
  }
}
