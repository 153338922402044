import { Injectable } from '@angular/core';
import { AuthUser, User } from 'src/app/shared/interfaces/AuthUser';
import { combineLatest, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { uniq } from 'lodash';
import { LocalStorageService } from 'ngx-localstorage';
import { AuthService } from 'src/app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  collection;
  usersCollection;
  private cache$: ReplaySubject<AuthUser> = new ReplaySubject<AuthUser>(1);
  public userId$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public userLoggedIn$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public userImage$: ReplaySubject<string> = new ReplaySubject<string>(1);

  public authUser$: ReplaySubject<AuthUser> = new ReplaySubject<AuthUser>(1);

  public authUser: AuthUser;

  private isCached = false;

  get user$(): Observable<AuthUser> {
      return this.cache$;
  }

  constructor(
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private storage: LocalStorageService,
    private authService: AuthService
  ) {

    this.collection = 'Users';

    this.afAuth.onAuthStateChanged((user) => {
      this.userLoggedIn$.next(!!user);
      if (user) {
        this.usersCollection = this.afStore.collection<any>(this.collection);
        this.authUser = user;
        if (!this.isCached) {
          this.refreshUser().subscribe();
          this.isCached = true;
        }
      }
    });
  }

  resetUser(): void {
    this.cache$.next(null);
    this.userId$.next(null);
    this.userLoggedIn$.next(null);
    this.isCached = false;

    // this.storage.clear();
    this.authService.signOut();
    window.location.reload();
  }

  refreshUser(): Observable<AuthUser>{
    this.isCached = true;

    return this.afStore.collection<AuthUser>('Users').doc(this.authUser.uid).get().pipe(
      tap((doc) => {
        if (doc.exists) {
          const userData: AuthUser = Object.assign(this.authUser, {data: doc.data()});
          this.cache$.next(userData);
          this.userId$.next(userData.uid);
        } else {
          this.cache$.next(this.authUser);
          this.userId$.next(this.authUser.uid);
          this.isCached = false;
          return throwError(of('NO DATA'));
        }
      }),
      catchError((err) => {
          this.isCached = false;
          return throwError(of(err));
      }),
    ) as Observable<AuthUser>;
  }

  putUserMeta(uid: string, body: any): Promise<any> {
    return this.usersCollection.doc(uid).set(body, { merge: true });
  }

  putUser(user: any, body: any): Promise<any> {
    return user.updateProfile(body);
  }

  putUserEmail(user: any, email: string): Promise<any> {
    return user.updateEmail(email);
  }

  getGodchildrens(ids: string[])  {
    // return this.afStore.collection(this.collection, ref => ref.where('uid', 'in', ids)).get();
    let i = 0;

    return this.afStore
      .collection(this.collection, ref => ref.where('uid', 'in', ids))
      .valueChanges()
      .pipe(
        switchMap(users => {
          const usersUid = uniq(users.map((user: User) => user.uid));

          return combineLatest(
            of(users),
            combineLatest(
              usersUid.map(userUid =>
                this.afStore
                  .collection<any>('Business', ref =>
                    ref.where('depositorUid', '==', userUid)
                  )
                  .valueChanges()
                  .pipe(map(business => business))
              )
            )
          );
        }),
        map(([users, business]) => {
          return users.map((user: User) => {
            const data = {
              ...user,
              business: {
                all: {
                  business: business[i].find(a => a?.depositorUid === user.uid),
                  count: business[i].filter(a => a?.depositorUid === user.uid)?.length
                },
                ongoing: {
                  business: business[i].find(a => a?.depositorUid === user.uid && a.status === 'ONGOING'),
                  count: business[i].filter(a => a?.depositorUid === user.uid && a.status === 'ONGOING')?.length
                },
                close: {
                  business: business[i].find(a => a?.depositorUid === user.uid && a.status === 'CLOSE'),
                  count: business[i].filter(a => a?.depositorUid === user.uid && a.status === 'CLOSE')?.length
                },
                notClose: {
                  business: business[i].find(a => a?.depositorUid === user.uid && a.status === 'NOT_CLOSE'),
                  count: business[i].filter(a => a?.depositorUid === user.uid && a.status === 'NOT_CLOSE')?.length
                }
              }
            };
            i++;
            return data;
          });
        })
      );
  }

  getGodchildrensBusiness(ids: string[])  {
    return this.afStore.collection('Business', ref => ref.where('depositorUid', 'in', ids)).get();
  }
}
