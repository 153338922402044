import { ChangeDetectorRef, Component } from '@angular/core';
import { QuerySnapshot } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize, map, take } from 'rxjs/operators';
import { User } from 'src/app/shared/interfaces/AuthUser';
import { UserService, CollectionsService } from '../../../shared/services';

interface Amounts {
  ongoing: number;
  close: number;
}

@Component({
  selector: 'app-godchildren',
  templateUrl: './godchildren.component.html',
  styleUrls: ['../../../shared/assets/styles/styles.scss', './godchildren.component.scss']
})
export class GodchildrenComponent {
  user?: any;
  userData?: User;
  godchildrens?: User[];
  subGodchildrens?: User[] | any[] = [];
  godchildrensCounts;
  subGodchildrensCounts;
  loadingGodchildren = false;
  godchildrenAmounts: Amounts = {ongoing: 0, close: 0};
  subGodchildrensAmounts: Amounts = {ongoing: 0, close: 0};

  constructor(
    private userService: UserService,
    private collectionsService: CollectionsService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.getUser();
  }

  getUser() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
      this.userData = user.data;
      this.getGodchildrens(user.data.godchildrenIds);
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    });
  }

  getGodchildrens(godchildrenIds, sub = false) {
    if (!sub) {
      this.loadingGodchildren = true;
    }

    if(godchildrenIds && godchildrenIds.length > 0) {
      this.userService.getGodchildrens(godchildrenIds)
      .pipe(
        finalize(() => {
          this.loadingGodchildren = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe(godchildrens => {
        if (sub) {
          this.subGodchildrens = godchildrens;
          this.subGodchildrensCounts = this.getbusinessCount(godchildrens);

          this.subGodchildrensAmounts = this.getAmounts(godchildrens, true);
        } else {
          this.godchildrenAmounts = this.getAmounts(godchildrens);
          this.godchildrens = godchildrens;
          this.godchildrensCounts = this.getbusinessCount(godchildrens);
          const godchildrenIds = [].concat.apply([], godchildrens.map(godchildren => godchildren.godchildrenIds));
          const godchildrenIdsFormated = godchildrenIds.filter(e => e !== undefined);
          if (godchildrenIdsFormated.length > 0) {
            this.getGodchildrens(godchildrenIdsFormated, true);
          }
          this.loadingGodchildren = false;
        }
      }, err => {
        this._snackBar.open('Une erreur est survenue', null, {
          duration: 3000,
        });
      });
    } else {
      this.loadingGodchildren = false;
      // no godchildren
    }
  }

  getAmounts(users, sub = false) {
    let amounts = {
      ongoing: 0,
      close: 0
    };

    users.forEach(u => {
      this.collectionsService.getCollectionByKeyValue('Business', 'depositorUid', u.uid)
      .pipe(
        map(
          (user: QuerySnapshot<any>) => user.docs.map(d => d.data()),
          take(1)
        )
      ).subscribe(business => {
        business.filter(value => value.status === 'ONGOING').forEach(b => {
          amounts.ongoing += (sub ? b.amount_3 : b.amount_2);
        });
        business.filter(value => value.status === 'CLOSE').forEach(b => {
          amounts.close += (sub ? b.amount_3 : b.amount_2);
        })
      }, () => {
        this._snackBar.open('Une erreur est survenue', null, {
          duration: 3000,
        });
      })
    });

    return amounts;
  }

  getbusinessCount(users) {
    const counts = {
      all: 0,
      ongoing: 0,
      close: 0,
      notClose: 0
    }

    users.forEach(user => {
      counts.all += user.business.all.count;
      counts.ongoing += user.business.ongoing.count;
      counts.close += user.business.close.count;
      counts.notClose += user.business.notClose.count;
    })

    return counts;
  }

  copy(label: string) {
    this._snackBar.open(label + ' copié', null, {
      duration: 3000,
    });
  }
}
