import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaqPageComponent, HomePageComponent } from '../landing/pages';
import { ClientGuard } from '../shared/guards/client.guard';
import { CompanyGuard } from '../shared/guards/company.guard';
import { PendingChangesGuard } from '../shared/guards/pending-changes.guard';

import { ClientComponent } from './client.component';
import {
    BusinessComponent,
    CharterProtectionComponent,
    ContactPageComponent,
    CreateBusinessComponent,
    GodchildrenComponent,
    ParametersComponent,
    ProfilComponent,
} from './pages';

const routes: Routes = [
    {
        path: '',
        component: ClientComponent,
        canActivate: [ClientGuard, CompanyGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'profil',
            },
            {
                path: 'profil',
                component: ProfilComponent,
            },
            {
                path: 'business',
                component: BusinessComponent,
            },
            {
                path: 'create-business',
                component: CreateBusinessComponent,
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'create-business/:uid',
                component: CreateBusinessComponent,
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'godchildren',
                component: GodchildrenComponent,
            },
            {
                path: 'parameters',
                component: ParametersComponent,
            },
            {
                path: 'charter-protection',
                component: CharterProtectionComponent,
            },
            {
                path: 'contact',
                component: ContactPageComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ClientRoutingModule {}
