import { Component, OnInit } from "@angular/core";
import { QuerySnapshot } from "@angular/fire/firestore";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { map, take } from "rxjs/operators";
import {
  CollectionsService,
  EmailTemplatesService,
  UserService,
} from "src/app/shared/services";
import { AuthService } from "../../services";

import * as moment from "moment";

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-register-page",
  templateUrl: "./register-page.component.html",
  styleUrls: ["./register-page.component.scss"],
})
export class RegisterPageComponent implements OnInit {
  form: FormGroup;
  loading = false;
  referralCode: string = null;

  samePasswordError = false;
  matcher = new MyErrorStateMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private collectionsService: CollectionsService,
    private emailTemplatesService: EmailTemplatesService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.referralCode = params.rf;
    });

    this.form = this.formBuilder.group({
      firstName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      lastName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      phoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("[0-9]{10}"),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      company: [
        "",
        Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      password: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          Validators.pattern(
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&*])/
          ),
        ]),
      ],
      passwordConfirm: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      politics: ["", Validators.compose([Validators.required])],
      referralCodeUsed: [this.referralCode],
      pubPerso: [""],
      pubGeo: [""],
      pubRS: [""],
    });

    if (this.referralCode) {
      this.form.get("referralCodeUsed").disable();
    }
  }

  ngOnInit() {}

  submit() {
    if (this.form.invalid) {
      this._snackBar.open(
        "Merci de vérifier le formulaire, le mot de passe doit contenir une majuscule, un charactère spécial et au minimum 12 charactères",
        null,
        {
          duration: 3000,
        }
      );
      return;
    }
    if (!this.form.get("politics").value) {
      this._snackBar.open(
        "Vous devez accepter la charte de protection des données personnelles",
        null,
        {
          duration: 3000,
        }
      );
      return;
    }
    if (
      this.form.get("password").value !== this.form.get("passwordConfirm").value
    ) {
      this._snackBar.open("Les mots de passe ne correponds pas", null, {
        duration: 3000,
      });
      return;
    }

    this.loading = true;

    this.authService
      .createUserWithEmailAndPassword(this.form.value)
      .then((res) => {
        res.user.updateProfile({
          displayName:
            this.form.get("firstName").value +
            " " +
            this.form.get("lastName").value,
        });

        delete this.form.value.password;
        delete this.form.value.passwordConfirm;

        const code = this.form.get("referralCodeUsed").value;
        let godchildrenIds = [];

        if (code) {
          this.collectionsService
            .getCollectionByKeyValue("Users", "referralCode", code)
            .pipe(
              map(
                (godfather: QuerySnapshot<any>) =>
                  godfather.docs.map((d) => d.data()),
                take(1)
              )
            )
            .subscribe(
              (godfather) => {
                if (godfather.length > 0) {
                  const bodyGodchildren = {
                    godfatherUid: godfather[0].uid,
                    referralCode: code,
                    godchildrenUid: res.user.uid,
                  };
                  this.collectionsService
                    .postDocumentCollection("Godchildrens", bodyGodchildren)
                    .then(() => {
                      godchildrenIds = godfather[0].godchildrenIds
                        ? godfather[0].godchildrenIds
                        : [];
                      godchildrenIds.push(res.user.uid);
                      this.userService
                        .putUserMeta(godfather[0].uid, {
                          godchildrenIds: godchildrenIds,
                        })
                        .then(() => {
                          const emailBody = {
                            to: godfather[0].email,
                            subject: "Vous avez un nouveau filleul !",
                            content:
                              this.emailTemplatesService.registerGodfather(),
                          };

                          this.collectionsService.postDocumentCollection(
                            "Emails",
                            emailBody
                          );
                        })
                        .catch(() => {
                          this._snackBar.open("Une erreur est survenue", null, {
                            duration: 3000,
                          });
                          this.loading = false;
                        });
                    })
                    .catch(() => {
                      this._snackBar.open("Une erreur est survenue", null, {
                        duration: 3000,
                      });
                    });
                }
              },
              (err) => {
                this._snackBar.open(
                  "Une erreur est survenue sur le lien de parainage",
                  null,
                  {
                    duration: 3000,
                  }
                );
              }
            );
        }

        const firstNameFormated = this.formatName(
          this.form.get("firstName").value,
          2
        );
        const lastNameFormated = this.formatName(
          this.form.get("lastName").value,
          3
        );

        const referralCode =
          firstNameFormated +
          lastNameFormated +
          ((Date.now() / 1000) | 0)
            .toString()
            .substring(6, ((Date.now() / 1000) | 0).toString().length);

        Object.assign(this.form.value, {
          referralCode: referralCode,
          uid: res.user.uid,
          createdAt: moment().format("DD/MM/YYYY"),
        });

        this.userService
          .putUserMeta(res.user.uid, this.form.value)
          .then(() => {
            this.loading = false;
            const emailsBody = [
              {
                to: "contact@saintclair-patrimoine.fr",
                subject: "Un nouvel ambassadeur enregistré !",
                content: this.emailTemplatesService.registerAdmin(
                  this.form.value
                ),
              },
              {
                to: this.form.get("email").value,
                subject: "Votre inscription est prise en compte",
                content: this.emailTemplatesService.registerUSer(referralCode),
              },
            ];

            emailsBody.forEach((emailBody) => {
              this.collectionsService.postDocumentCollection(
                "Emails",
                emailBody
              );
            });

            this.router.navigate(["/home"]);
          })
          .catch(() => {
            this._snackBar.open("Une erreur est survenue", null, {
              duration: 3000,
            });
            this.loading = false;
          });
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          this._snackBar.open("Un compte existe déjà avec cet email", null, {
            duration: 3000,
          });
          this.loading = false;
          return;
        }
        this._snackBar.open("Une erreur est survenue", null, {
          duration: 3000,
        });
        this.loading = false;
      });
  }

  formatName(name: string, sub: number) {
    return name
      .trim()
      .toUpperCase()
      .split(" ")
      .join("")
      .split("-")
      .join("")
      .substring(0, sub)
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
  }
}
