import { ChangeDetectorRef, Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CollectionsService,
  EmailTemplatesService,
  UserService,
} from "../../../shared/services";

import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { map, take } from "rxjs/operators";
import { Business } from "src/app/shared/interfaces/Business";
import { User } from "src/app/shared/interfaces/AuthUser";
import { QuerySnapshot } from "@angular/fire/firestore";

@Component({
  selector: "app-create-business",
  templateUrl: "./create-business.component.html",
  styleUrls: [
    "../../../shared/assets/styles/styles.scss",
    "./create-business.component.scss",
  ],
})
export class CreateBusinessComponent {
  form: FormGroup;
  loading = false;
  user?: any;
  userData?: User;
  businessUid?: string;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private collectionsService: CollectionsService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private emailTemplatesService: EmailTemplatesService
  ) {
    this.form = this.formBuilder.group({
      firstName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      lastName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      phoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern("[0-9]{10}"),
        ]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
      origin: [""],
      comment: [""],
      politics: ["", Validators.compose([Validators.required])],
    });

    if (this.route.snapshot.params.uid) {
      this.businessUid = this.route.snapshot.params.uid;
      this.collectionsService
        .getCollectionByDoc("Business", this.businessUid)
        .pipe(
          map((doc) => doc.data()),
          take(1)
        )
        .subscribe((data: Business) => {
          Object.assign(data, { uid: this.businessUid });
          this.form.patchValue(data);
          this.cdr.markForCheck();
        });
    }

    this.getUser();

    this.form.get("origin").valueChanges.subscribe((data) => {
      if (data === "other") {
        this.form.addControl(
          "origin_other",
          new FormControl("", Validators.required)
        );
      } else {
        this.form.removeControl("origin_other");
      }
    });
  }

  getUser() {
    this.userService.user$.subscribe(
      (user) => {
        this.user = user;
        this.userData = user.data;
      },
      () => {
        this._snackBar.open("Une erreur est survenue", null, {
          duration: 3000,
        });
      }
    );
  }

  submit() {
    if (this.form.invalid) {
      this._snackBar.open("Merci de vérifier le formulaire", null, {
        duration: 3000,
      });
      return;
    }

    if (this.form.get("origin").value === "other") {
      this.form.value.origin = "Autre : " + this.form.get("origin_other").value;
    }

    Object.assign(this.form.value, {
      createdAt: moment().format("DD/MM/YYYY"),
      status: "PENDING",
      depositorUid: this.user.uid,
      createdBy: this.userData,
      amount_1: 1500,
      amount_2: 500,
      amount_3: 250,
    });

    this.collectionsService
      .getCollection("Business")
      .pipe(
        map((business: QuerySnapshot<any>) =>
          business.docs
            .map((d) => d.data())
            .filter(
              (val) =>
                val.phoneNumber === this.form.get("phoneNumber").value ||
                val.email === this.form.get("email").value
            )
        )
      )
      .subscribe((data) => {
        if (data.length > 0 && !this.businessUid) {
          this._snackBar.open(
            "Merci de nous avoir transmis ce contact. Malheureusement celui-ci a déjà été transmis par un autre ambassadeur.  N'hésitez pas à déposer un autre contact et peut-être toucher 1500€ de commission.",
            null,
            {
              duration: 9000,
            }
          );
          return;
        } else {
          if (this.businessUid) {
            Object.assign(this.form.value, {
              updatedAt: moment().format("DD/MM/YYYY"),
            });
            this.collectionsService
              .putDocumentCollectionWithUid(
                "Business",
                this.businessUid,
                this.form.value
              )
              .then(() => {
                this.form.reset();
                this.form.markAsPristine();
                this.router.navigate(["/client/business"]);
              })
              .catch(() => {
                this._snackBar.open("Une erreur est survenue", null, {
                  duration: 3000,
                });
              });
          } else {
            this.collectionsService
              .postDocumentCollection("Business", this.form.value)
              .then(() => {
                const emailsBody = [
                  {
                    to: "contact@saintclair-patrimoine.fr",
                    subject: `Nouvelle affaire déposée par ${this.userData.lastName} ${this.userData.firstName} !`,
                    content: this.emailTemplatesService.createBusinessAdmin(
                      this.userData,
                      this.form.value
                    ),
                  },
                  {
                    to: this.userData.email,
                    subject: "Votre affaire est déposée !",
                    content: this.emailTemplatesService.createBusinessUser(),
                  },
                  {
                    to: this.form.get("email").value,
                    subject: "Et si on parlait de votre projet ?",
                    content: this.emailTemplatesService.createBusinessProspect(
                      this.userData
                    ),
                  },
                ];

                emailsBody.forEach((emailBody) => {
                  this.collectionsService.postDocumentCollection(
                    "Emails",
                    emailBody
                  );
                });
                this.form.reset();
                this.form.markAsPristine();
                this.router.navigate(["/client/business"]);
              })
              .catch(() => {
                this._snackBar.open("Une erreur est survenue", null, {
                  duration: 3000,
                });
              });
          }
        }
      });
  }

  canDeactivate(): boolean {
    return !this.form.dirty;
  }

  canDeactivateMessage(): string {
    return "Les modifications n'ont pas été enregistrées, êtes-vous sûr ?";
  }
}
