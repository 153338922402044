import { Component } from '@angular/core';
import { QuerySnapshot } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { User } from '../../interfaces/AuthUser';
import { Business } from '../../interfaces/Business';
import { CollectionsService, UserService } from '../../services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['../../assets/styles/styles.scss', './sidebar.component.scss']
})
export class SidebarComponent {
  user?: any;
  userData?: User;
  allPendingBusiness?: Business[];
  allOngoingBusiness?: Business[];

  constructor(
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private collectionsService: CollectionsService
  ) {
    this.getUser();
  }

  getUser() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
      this.userData = user.data;
      this.getAllBusiness();
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    });
  }

  getAllBusiness() {

    this.collectionsService.getCollection('Business')
    .pipe(
      map(
        (business: QuerySnapshot<any>) => business.docs.map(d => d.data())
      )
    ).subscribe(business => {
      this.allPendingBusiness = business.filter(value => value.status === 'PENDING');
      this.allOngoingBusiness = business.filter(value => value.status === 'ONGOING');
    }, err => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    })
  }
}
