import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {

  constructor(
    private afStore: AngularFirestore,
  ) { }

  getCollectionByKeyValue(collection: string, key: string, value: any) {
    return this.afStore.collection(collection, ref => ref.where(key, '==', value)).get();
  }

  getCollection(collection: string) {
    return this.afStore.collection(collection).get();
  }

  getCollectionByDoc(collection: string, doc: string) {
    return this.afStore.collection(collection).doc(doc).get();
  }

  postDocumentCollection(collection: string, body: any) {
    return this.afStore.collection<any>(collection).add(body);
  }

  putDocumentCollectionWithUid(collection: string, uid: string, body: any) {
    return this.afStore.collection<any>(collection).doc(uid).set(body, { merge: true });
  }

  deleteDoc(collection: string, uid: string) {
    return this.afStore.collection(collection).doc(uid).delete();
  }


  getBusiness(limite: number, order: 'asc' | 'desc', orderBy: string) {
    return this.afStore.collection('Business', ref => ref
      .orderBy(orderBy, order)
      .limit(limite)
    ).get();
  }

  getBusinessByUid(limite: number = 10, order: 'asc' | 'desc' = 'asc', orderBy: string = 'createdAt', companyId: string) {
    return this.afStore.collection('Business', ref => ref
      .where('companyId', '==', companyId)
      .limit(limite)
    ).get();
  }

  getAmbassadors(limite: number, order: 'asc' | 'desc', orderBy: string) {
    return this.afStore.collection('Users', ref => ref
      .orderBy(orderBy, order)
      .limit(limite)
    ).get();
  }

  getAllAmbassadors() {
    return this.afStore.collection('Users', ref => ref).get();
  }

  getCompanies(limite: number, order: 'asc' | 'desc', orderBy: string) {
    return this.afStore.collection('Companies', ref => ref
      .orderBy(orderBy, order)
      .limit(limite)
    ).get();
  }

  searchBusiness() {
    return this.afStore.collection('Business', ref => ref).get();
  }

  searchAmbassadors() {
    return this.afStore.collection('Users', ref => ref).get();
  }

  searchCompanies() {
    return this.afStore.collection('Companies', ref => ref).get();
  }

  getCollaborators(limite: number = 10, order: 'asc' | 'desc' = 'asc', orderBy: string = 'createdAt', companyId: string) {
    return this.afStore.collection('Users', ref => ref
      .where('companyId', '==', companyId)
      .limit(limite)
    ).get();
  }

  getAllCollaborators() {
    return this.afStore.collection('Users', ref => ref).get();
  }

  searchAllCollaborators() {
    return this.afStore.collection('Users', ref => ref.where('companyId', '!=', null)).get();
  }

  searchCollaborators(companyId: string) {
    return this.afStore.collection('Users', ref => ref.where('companyId', '==', companyId)).get();
  }
}
