import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { AuthModule } from './auth/auth.module';
import { ClientModule } from './client/client.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { AdminGuard } from './shared/guards/admin.guard';
import { ClientGuard } from './shared/guards/client.guard';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule } from '@angular/common/http';
import { PendingChangesGuard } from './shared/guards/pending-changes.guard';
import { CompanyGuard } from './shared/guards/company.guard';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,

    AppRoutingModule,

    SharedModule,
    ClientModule,
    AuthModule,
    BrowserAnimationsModule,
    NgxLocalStorageModule.forRoot(),

    AngularFireModule.initializeApp({
      apiKey: "AIzaSyAtpSpIQfVxjkDKr07Uio-W4ozti0jq8CY",
      authDomain: "immosansfrais.firebaseapp.com",
      projectId: "immosansfrais",
      storageBucket: "immosansfrais.appspot.com",
      messagingSenderId: "131549828102",
      appId: "1:131549828102:web:4d48992a95c2e289d65f87",
      measurementId: "G-Q4L0DM0H18"
    }, 'immosansfrais'),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    HttpClientModule,
  ],
  providers: [
    AdminGuard,
    ClientGuard,
    CompanyGuard,
    PendingChangesGuard,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
