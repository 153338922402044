import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'app-logged-menu',
  templateUrl: './logged-menu.component.html',
  styleUrls: ['./logged-menu.component.scss']
})
export class LoggedMenuComponent implements OnInit {
  isOnAdmin: boolean = false;

  constructor(
    private storage: LocalStorageService
  ) {
    if (this.storage.get('admin')) {
      this.isOnAdmin = true;
    }
  }

  ngOnInit() {
  }

}
