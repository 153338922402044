import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { QuerySnapshot } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, take, finalize } from 'rxjs/operators';
import { User } from 'src/app/shared/interfaces/AuthUser';
import { CollectionsService, UserService } from '../../../shared/services';

interface Amounts {
  ongoing: number;
  close: number;
}

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['../../../shared/assets/styles/styles.scss', './profil.component.scss']
})
export class ProfilComponent implements OnInit {
  user?: any;
  userData?: User;
  jackPot: number = 0;
  potentialPot: number = 0;
  subGodchildrensCounts;
  godchildrensCounts;
  lastBusiness: any[];
  godchildrenAmounts: Amounts = {ongoing: 0, close: 0};
  subGodchildrensAmounts: Amounts = {ongoing: 0, close: 0};

  constructor(
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private collectionsService: CollectionsService,
    private cdr: ChangeDetectorRef
  ) {
    this.getUser();
  }

  ngOnInit() {
  }

  getUser() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
      this.userData = user.data;
      this.getBusiness(user.uid);
      if (user.data) {
        this.getGodchildrens(user.data.godchildrenIds);
      }
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    });
  }

  getBusiness(userUid: string) {
    this.collectionsService.getCollectionByKeyValue('Business', 'depositorUid', userUid)
    .pipe(
      map(
        (godfather: QuerySnapshot<any>) => godfather.docs.map(d => d.data()),
        take(1)
      )
    ).subscribe(business => {
      this.lastBusiness = business.slice(0, 2);
      business.filter(value => value.status === 'ONGOING').forEach(b => {
        this.potentialPot += b.amount_1;
      });
      business.filter(value => value.status === 'CLOSE').forEach(b => {
        this.jackPot += b.amount_1;
      })
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    })
  }

  getGodchildrens(godchildrenIds, sub = false) {
    if(godchildrenIds && godchildrenIds.length > 0) {
      this.userService.getGodchildrens(godchildrenIds)
      .pipe(
        finalize(() => {
          this.cdr.markForCheck();
        })
      )
      .subscribe(godchildrens => {
        if (sub) {
          this.subGodchildrensCounts = this.getbusinessCount(godchildrens);

          this.subGodchildrensAmounts = this.getAmounts(godchildrens, true);
        } else {
          this.godchildrenAmounts = this.getAmounts(godchildrens);

          this.godchildrensCounts = this.getbusinessCount(godchildrens);
          const godchildrenIds = [].concat.apply([], godchildrens.map(godchildren => godchildren.godchildrenIds));
          this.getGodchildrens(godchildrenIds, true);
        }
      }, err => {
        this._snackBar.open('Une erreur est survenue', null, {
          duration: 3000,
        });
      });
    } else {
      // no godchildren
    }
  }

  getAmounts(users, sub = false) {
    let amounts = {
      ongoing: 0,
      close: 0
    };

    users.forEach(u => {
      this.collectionsService.getCollectionByKeyValue('Business', 'depositorUid', u.uid)
      .pipe(
        map(
          (user: QuerySnapshot<any>) => user.docs.map(d => d.data()),
          take(1)
        )
      ).subscribe(business => {
        business.filter(value => value.status === 'ONGOING').forEach(b => {
          amounts.ongoing += (sub ? b.amount_3 : b.amount_2);
        });
        business.filter(value => value.status === 'CLOSE').forEach(b => {
          amounts.close += (sub ? b.amount_3 : b.amount_2);
        })
      }, () => {
        this._snackBar.open('Une erreur est survenue', null, {
          duration: 3000,
        });
      })
    });

    return amounts;
  }

  getbusinessCount(users) {
    const counts = {
      all: 0,
      ongoing: 0,
      close: 0,
      notClose: 0
    }

    users.forEach(user => {
      counts.all += user.business.all.count;
      counts.ongoing += user.business.ongoing.count;
      counts.close += user.business.close.count;
      counts.notClose += user.business.notClose.count;
    })

    return counts;
  }
}
