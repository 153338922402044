import { Injectable } from "@angular/core";
import { User } from "../interfaces/AuthUser";
// import { Business } from "../interfaces/Business";

@Injectable({
  providedIn: "root",
})
export class EmailTemplatesService {
  constructor() {}

  contactForm(formData) {
    return `<p>Vous avez reçu un nouveau message via le formulaire de contact sur <a href="ambassadeurs.saintclair-patrimoine.fr">ambassadeurs.saintclair-patrimoine.fr</a>.</p>
      <p>Nom : ${formData.lastName}</p>
      <p>Prénom : ${formData.firstName}</p>
      <p>Email : ${formData.email}</p>
      <p>Message : ${formData.message}</p>


      <html lang="fr">

<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
    rel="stylesheet">
  <title>Vous avez un nouveau message !</title>
</head>

<body>
  <div style="
        margin: 20px auto;
        width: 600px;
        text-align: center;
        font-family: Nunito Sans, Helvetica, sans-serif;
      ">
    <div style="margin: 20px auto 40px auto">
      <img style="width: 150px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
        alt="logo" />
    </div>
    <div style="background-color: #9CCCF1; padding: 20px; ">
      <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
        Vous avez un nouveau petit filleul !
      </p>
    </div>
    <div style="margin: 40px auto">
      <p style="color: #002855; font-size: 20px; line-height: 141%; font-family: Josefin Sans; font-weight: 700;">
        Bonjour, vous avez reçu un nouveau message sur le site
        <span style="color: #F08865;">ambassadeurs.saintclair-patrimoine.fr</span><br /><br />
      </p>
      <p style="color: #002855; font-size: 13px; line-height: 190%;">
        NOM: ${formData.lastName}<br />
        PRENOM: ${formData.firstName}<br />
        EMAIL: ${formData.email}<br />
        TELEPHONE: ${formData.phoneNumber}
      </p>
      <p style="color: #002855; text-align: left; font-family: Josefin Sans; padding-left: 25px; font-weight: 700;">
        Message :</p>
      <div style="background-color: #002855; padding: 20px; text-align: left">
        <p style="color: #FFF">
        ${formData.message}
        </p>
      </div>
      <img style="width: 90px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
        alt="logo" />
    </div>
  </div>
</body>

</html>`;
  }

  contactFormUser() {
    return `<!DOCTYPE html>
    <html lang="fr">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Message envoyé à Saint-Clair.</title>
    </head>
    <body>
      <div style="
            margin: 20px auto;
            width: 600px;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
          ">
        <div style="margin: 20px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #9CCCF1; padding: 20px; ">
          <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
            Message envoyé à Saint-Clair !
          </p>
        </div>
        <div style="margin: 40px auto;">
          <p style="color: #002855; font-size: 20px; font-family: Josefin Sans; font-weight: 700;">
            <span style="color: #F08865;">Bonjour</span>, votre message a bien été transmis à nos équipes.
          </p>
          <p style="color: #002855; font-size: 13px;padding: 0 70px;">
            Elles se chargeront de revenir vers vous dans les plus brefs délais.
          </p>
          <p style="color: #002855; font-size: 13px;">À bientôt,</p>
          <p style="color: #002855; font-weight: 700; font-family: Josefin Sans;">L’équipe Saint-Clair !</p>
          <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
            alt="logo" />
        </div>
      </div>
    </body>
    
    </html>`;
  }

  registerUSer(referralCode) {
    return `<!DOCTYPE html>
    <html lang="fr">

<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
    rel="stylesheet">
  <title>Votre inscription est prise en compte</title>
</head>

<body>
  <div style="
        margin: 20px auto;
        text-align: center;
        font-family: Nunito Sans, Helvetica, sans-serif;
        width: 600px;
      ">
    <div style="margin: 50px auto 40px auto">
      <img style="width: 150px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
        alt="logo" />
    </div>
    <div style="background-color: #9CCCF1; padding: 15px 0 20px 0">

      <p style="
            color: #FFF;
            font-size: 32px;
            margin-bottom: 0px;
            font-family: Josefin Sans, Helvetica, sans-serif;
          ">
        Félicitations !
      </p>
      <p style="color: #FFF; margin-top: 10px">
        vous êtes officiellement <b>ambassadeurs Saint-Clair</b>.
      </p>
    </div>
    <div style="margin: 40px auto; text-align: center;">
      <div style="padding: 15px;">
        <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
          Vous pouvez désormais déposer votre <span style="color: #F08865;">première affaire !</span>
        </p>
        <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
              background-color: #002855;
              color: #ffffff;
              text-decoration: none;
              font-size: 22px;
              padding: 10px 20px;
              display: inline-block;
              border-radius: 3px;
              font-family: Josefin Sans, Helvetica, sans-serif;
              margin: 15px 0 20px 0;
            ">Déposer votre affaire</a>
        <p style="
              color: #002855;
              margin: 30px auto;
              font-weight: bold;
              font-size: 14px;
              font-style: italic;
            ">
        </p>
        <p style="color: #002855">
          Votre code parrain :
          <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                background-color: #FFF;
                color: #002855;
                text-decoration: none;
                font-size: 18px;
                padding: 10px 45px;
                display: inline-block;
                border: solid #002855 1px;
                border-radius: 3px;
                font-family: Josefin Sans, Helvetica, sans-serif;
              ">${referralCode}</a>
        </p>
      </div>

      <div style="margin: 40px auto; background: #002855; padding: 15px 60px 0 60px;">
        <p style="
        color: #FFF;
        font-weight: bold;
        margin-bottom: 0px;
        font-size: 20px;
        font-family: Josefin Sans, Helvetica, sans-serif;
      ">
          Gagnez au moins 500€ supplémentaires
        </p>
        <p style="color: #FFF; margin-top: 15px; font-size: 13px;">
          pour tout filleul parrainé qui apporte un contact pour lequel
          l’affaire est conclue. Plus vous avez de filleuls et de petits
          filleuls, plus vous augmentez vos revenus.
        </p>
        <p style="color: #FFF; font-size: 13px;">À bientôt,</p>
        <p style="color: #FFF; font-size: 13px;">
          *Si le montant n’apparaît pas au moment de votre inscription, sachez
          que nos équipes le comptabilise dès lors que vous déposez un contact.
        </p>
        <p style="color: #FFF; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
        <img style="width: 90px"
          src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20blanc%201.svg?alt=media&token=797ea472-2554-415c-af89-de1faddc5320&_gl=1*zdeufq*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIzNDkxOC4yNi4xLjE2OTgyMzQ5MjAuNTguMC4w"
          alt="logo" />
      </div>

    </div>
  </div>
</body>

</html>`;
  }

  registerAdmin(user) {
    return `<!DOCTYPE html>
    <html lang="fr">
<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
    rel="stylesheet">
  <title>Un nouvel ambassadeur enregistré !</title>
</head>

<body>
  <div style="
        margin: 20px auto;
        width: 600px;
        text-align: center;
        font-family: Nunito Sans, Helvetica, sans-serif;
      ">
    <div style="margin: 20px auto 40px auto">
      <img style="width: 150px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
        alt="logo" />
    </div>
    <div style="background-color: #F08865; padding: 10px;">
      <p style="color: #FFF; font-family: Josefin Sans; font-size: 32px">
        Un nouvel ambassadeur enregistré !
      </p>
    </div>
    <div style="margin: 40px auto">
      <p style="color: #002855; font-size: 14px; line-height: 190%;">
        <span style="font-size: 20px; font-family: Josefin Sans; font-weight: 700; line-height: 147%;">
          Bonjour, un nouvel ambassadeur a rejoint le site
          <span style="color: #F08865;">ambassadeurs.saintclair-patrimoine.fr le ${Intl.DateTimeFormat(
            "fr-FR",
            {}
          ).format(Date.now())}.</span> <br /><br /></span>
        <b>Les informations de ce nouvel ambassadeur : </b><br /><br />
        NOM: ${user.lastName}<br />
        PRENOM: ${user.firstName}<br />
        EMAIL: ${user.email}<br />
        TELEPHONE: ${user.phoneNumber}
      </p>
      <p>
        <a href="......" style="
              background-color: #002855;
              color: #ffffff;
              text-decoration: none;
              font-size: 22px;
              padding: 10px 20px;
              display: inline-block;
              border-radius: 3px;
              font-family: Josefin Sans, Helvetica, sans-serif;
              margin: 15px 0 20px 0;
            ">Consulter sa fiche</a>
      </p>
      <img style="width: 90px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
        alt="logo" />
    </div>
  </div>
</body>

</html>`;
  }

  registerGodfather() {
    return `<!DOCTYPE html>
    <html lang="fr">

    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Vous avez un nouveau filleul !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            width: 600px;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
          ">
        <div style="margin: 20px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #9CCCF1; padding: 10px;">
          <p style="color: #fff; font-size: 32px; font-family: Josefin Sans;">
            Vous avez un nouveau filleul !
          </p>
        </div>
        <div style="margin: auto; padding: 25px 70px;">
          <p style="color: #002855; font-size: 20px; font-family: Josefin Sans; font-weight: 700; line-height: 141%;">
            Bonjour, un nouvel ambassadeur s'est inscrit sur Saint-Clair <span style="color: #F08865;">avec votre
              code parrain !</span>
          </p>
          <p style="color: #002855; font-size: 13px;">
            À chaque affaire que ce dernier déposera
            <b>vous pourrez gagner 500€</b>. <br /><br />
            Pour rappel, si votre filleul parraine à son tour un filleul, vous
            <b>gagnez 500€</b> pour chaque affaire conclue par vos petits
            filleuls. <br />
            Vous pouvez consulter tous vos filleuls et vos petits filleuls via
            votre espace.<br /><br />
            Continuez à faire grandir votre communauté de filleuls et petits
            filleuls pour augmenter toujours plus vos revenus !<br /><br />
            Vous pouvez consulter tous vos filleuls via votre espace.
          </p>
          <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                background-color: #002855;
                color: #ffffff;
                text-decoration: none;
                font-size: 22px;
                padding: 10px 20px;
                display: inline-block;
                border-radius: 3px;
                font-family: Josefin Sans;
                margin: 30px 0 35px 0;
              ">Connexion</a>
          <p style="color: #585857; font-weight: bold; font-family: Josefin Sans;">L’équipe Saint-Clair !</p>
          <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
            alt="logo" />
        </div>
      </div>
    </body>`;
  }

  createBusinessAdmin(user, prospector) {
    return `<!DOCTYPE html>
    <html lang="fr">

    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Nouvelle affaire déposée par ${user.firstName} ${user.lastName} !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            width: 600px;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
          ">
        <div style="margin: 20px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
          <div style="background-color: #F08865; padding: 20px; margin-top: 20px; ">
            <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
              Nouvelle affaire déposée par ${user.firstName} ${user.lastName} !
            </p>
          </div>
          <div style="margin: 40px auto">
            <p style="color: #002855; line-height: 190%;">
              <span style=" font-family: Josefin Sans; font-size: 20px; font-weight: 700; line-height: 147%;">Bonjour, une
                nouvelle affaire a
                été déposée sur le site
                <span style="color: #F08865;">ambassadeurs.saintclair-patrimoine.fr</span> par ${user.firstName} ${user.lastName}.</span>
              <br /><br />
              <b>Voici les informations de cette affaire :</b><br /><br />
              NOM: ${prospector.lastName}<br />
              PRENOM: ${prospector.firstName}<br />
              EMAIL: ${prospector.email}<br />
              TELEPHONE: ${prospector.phoneNumber} <br /><br />
              <b>Connectez-vous sur votre espace administrateur pour la consulter et
                changer son statut.</b>
            </p>
            <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                background-color: #002855;
                color: #ffffff;
                text-decoration: none;
                font-size: 22px;
                padding: 10px 20px;
                display: inline-block;
                border-radius: 3px;
                font-family: Josefin Sans;
                margin: 30px 0 30px 0;
              ">Connexion</a>
            <p style="color: #002855; font-weight: bold">L’équipe Saint-Clair !</p>
          </div>
          <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
            alt="logo" />
        </div>
    </body>
    
    </html>`;
  }

  createBusinessUser() {
    return `<!DOCTYPE html>
    <html lang="fr">

<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
    rel="stylesheet">
  <title>Votre affaire est déposée !</title>
</head>

<body>
  <div style="
        margin: 20px auto;
        width: 600px;
        text-align: center;
        font-family: Nunito Sans, Helvetica, sans-serif;
      ">
    <div style="margin: 20px auto 40px auto">
      <img style="width: 150px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
        alt="logo" />
    </div>
    <div style="background-color: #9CCCF1; padding: 20px; ">
      <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
        Votre affaire est déposée !
      </p>
    </div>
    <div style="margin: 40px auto">
      <p style="color: #002855; font-size: 20px; font-family: Josefin Sans; font-weight: 700;">
        Super, votre affaire a bien été déposée !
      </p>
      <p style="color: #002855; font-size: 14px; font-style: italic; font-weight: 700;">
        Quelles sont les prochaines étapes ?
      </p>
      <div
        style="text-align: left; margin: auto 40px; font-size: 13px; color: #002855; border: solid 1px #002855; border-left: 0; padding: 30px 0;">
        <p>
          <span style="
                color: #F08865;
                font-family: Josefin Sans;
                padding: 9.5px;
                margin-right: 5px;
                font-size: 32px;
              ">1</span>
          Nos équipes s'occupent de votre affaire !
        </p>
        <p style="
        margin-top: 30px;">
          <span style="
                color: #F08865;
                font-family: Josefin Sans;
                padding: 5px;
                border-radius: 5px;
                margin-right: 5px;
                font-size: 32px;
              ">2</span>
          Vous suivez l’avancée de l’affaire directement via votre espace
          client.
        </p>
        <div style="text-align: center; margin: 30px auto 20px auto">
          <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
            background-color: #002855;
            color: #ffffff;
            text-decoration: none;
            font-size: 22px;
            padding: 10px 20px;
            display: inline-block;
            border-radius: 3px;
            font-family: Josefin Sans;
            margin: 30px 0 30px 0;
          ">Espace Ambassadeurs</a>
        </div>
        <div>
          <span style="
                color: #F08865;
                font-family: Josefin Sans;
                padding: 5px;
                border-radius: 5px;
                margin-right: 5px;
                font-size: 32px;
                display: inline-block;
                width: 12px;
                vertical-align: top;
                margin-top: 8px;
              ">3</span>
          <p style="display: inline-block; width: 420px; vertical-align: top">
            Vous voyez indiqué « Affaire conclue » ? Votre contact a acheté un
            bien grâce à Saint-Clair*.
            <b style="color: #F08865;">Félicitations, cela vous rapporte 1500€</b>.
          </p>
        </div>
      </div>
      <p style="color: #002855; font-weight: bold; margin-top: 10px;">À très bientôt</p>
      <small style="color: #002855; margin-top: 40px; font-size: 12px; font-style: italic;">
        * Il existe des délais légaux lors de l’achat d’un bien. Quelques mois
        sont à prévoir entre le dépôt de votre affaire sur notre plateforme et
        l’achat d’un bien qui mène vers votre commission.
      </small><br>
      <img style="width: 90px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
        alt="logo" />
    </div>
  </div>
</body>

</html>`;
  }

  createBusinessProspect(user) {
    return `<!DOCTYPE html>
    <html lang="fr">

    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Et si on parlait de votre projet ?</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            width: 600px;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
          ">
        <div style="margin: 20px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #002855; padding: 20px; ">
          <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
            Et si on parlait de votre projet ?
          </p>
        </div>
        <div style="margin: 40px auto; ">
          <p
            style="color: #002855; font-size: 20px; font-family: Josefin Sans; font-weight: 700; line-height: 141%;width: 420px; margin: auto;">
            Bonjour, <br />
            <span style="color: #F08865;">${user.firstName} ${user.lastName} </span> a confié votre projet à nos experts Saint-Clair.
          </p>
          <p style="color: #002855; font-size: 13px; width: 520px; margin: 20px auto;">
            Nous allons très vite prendre contact avec vous afin d’évoquer votre
            projet et de savoir comment nous pouvons vous aider à le réaliser.
          </p>
          <p style="color: #002855; font-size: 13px; margin-bottom: 30px;">Cordialement,</p>
          <p style="color: #002855; font-weight: bold; font-family: Josefin Sans;">L’équipe Saint-Clair !</p>
        </div>
        <img style="width: 90px"
          src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
          alt="logo" />
      </div>
    </body>
    
    </html>`;
  }

  // TODO
  businessChangeStatus(body) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
        <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Le statut de votre affaire à changé !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #9CCCF1; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
                text-wrap: balance;
              ">
            Saint-Clair a changé le statut de votre affaire concernant le contact</br> <{NOM_AFFAIRE PRENOM_AFFFAIRE}>
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance; line-height: 150%;">
              Consultez l'état d'avancée de votre affaire directement <span style="color: #F08865;">sur votre Espace Collaborateur :</span>
            </p>
            <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                  background-color: #002855;
                  color: #ffffff;
                  text-decoration: none;
                  font-size: 22px;
                  padding: 10px 20px;
                  display: inline-block;
                  border-radius: 3px;
                  font-family: Josefin Sans, Helvetica, sans-serif;
                  margin: 15px 0 20px 0;
                ">Espace Collaborateur</a>
          </div>
    
            <p style="color: #002855; font-size: 13px;">À bientôt,</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }
  // TODO

  businessClose() {
    return `<!DOCTYPE html>
    <html lang="fr">

    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Votre affaire est conclue !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            width: 600px;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
          ">
        <div style="margin: 20px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #9CCCF1; padding: 20px; ">
          <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
            Votre affaire est conclue !
          </p>
        </div>
        <div style="margin: 40px auto">
          <p style="color: #002855; font-family: Josefin Sans; font-weight: bold; font-size: 20px">
            Grâce à votre affaire, <span style="color: #F08865;">vous remportez 1500€ !</span>
          </p>
          <p style="color: #002855">
            Rendez-vous sur votre espace ambassadeur pour déposez une nouvelle
            affaire.
          </p>
          <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
              background-color: #002855;
              color: #ffffff;
              text-decoration: none;
              font-size: 22px;
              padding: 10px 20px;
              display: inline-block;
              border-radius: 3px;
              font-family: Josefin Sans;
              margin: 30px 0 30px 0;
            ">Espace Ambassadeur</a>
          <p style="color: #002855">À bientôt,</p>
          <p style="color: #002855; font-family: Josefin Sans; font-weight: bold">L’équipe Saint-Clair !</p>
        </div>
        <img style="width: 90px"
          src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
          alt="logo" />
      </div>
    </body>
    
    </html>`;
  }

  referralCode(url: string, userData: User) {
    return `
      <!DOCTYPE html>
      <html lang="fr">

<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
    rel="stylesheet">
  <title>${userData.lastName} ${userData.firstName} souhaite vous parrainer sur Saint-Clair !</title>
</head>

<body>
  <div style="
        margin: 20px auto;
        width: 600px;
        text-align: center;
        font-family: Nunito Sans, Helvetica, sans-serif;
      ">
    <div style="margin: 20px auto 40px auto">
      <img style="width: 150px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
        alt="logo" />
    </div>
    <div style="background-color: #9CCCF1; padding: 20px; ">
      <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
      ${userData.lastName} ${userData.firstName} souhaite vous parrainer sur Saint-Clair !
      </p>
    </div>
    <div style="margin: 40px auto">
      <p style="color: #002855; font-size: 13px;">
        <b style="font-family: Josefin Sans; font-size: 20px;">Cliquez sur le lien ci-dessous pour vous inscrire :</b>
        <br /><br />
        <span style="color: #F08865; font-style: italic;">${url}</span>
        <br /><br />
        Déposez votre première affaire et développez vous aussi votre réseau
        <br />
        d'ambassadeurs grâce à Saint-Clair.
        <br /><br />
        À bientôt,
      </p>
      <p style="color: #002855; font-weight: bold">L’équipe Saint-Clair !</p>
    </div>
    <img style="width: 90px"
      src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"
      alt="logo" />
  </div>
</body>

</html>
    `;
  }

  apafValidated() {
    return `
    <!DOCTYPE html>
    <html lang="fr">

<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
    rel="stylesheet">
  <title>Votre contrat d’apporteur d’affaires est signé !</title>
</head>

<body>
  <div style="
        margin: 20px auto;
        width: 600px;
        text-align: center;
        font-family: Nunito sans, Helvetica, sans-serif;
      ">
    <div style="margin: 20px auto 40px auto">
      <img style="width: 150px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
        alt="logo" />
    </div>
    <div style="background-color: #9CCCF1; padding: 20px; ">
      <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
        Félicitations !
      </p>
      <p style="color: #FFF;">
        votre contrat d’apporteur d’affaires est signé !
      </p>
    </div>
    <div style="margin: 40px auto">
      <p style="color: #002855; font-weight: bold; font-size: 20px">
        Déposez votre première affaire et <span style="color: #F08865;">gagnez 1500€.</span>
      </p>
      <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
          background-color: #002855;
          color: #ffffff;
          text-decoration: none;
          font-size: 22px;
          padding: 10px 20px;
          display: inline-block;
          border-radius: 3px;
          font-family: Josefin Sans;
          margin: 30px 0 30px 0;
        ">Déposer votre affaire</a>
      <p style="color: #002855">
        Votre code parrain :
        <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
              background-color: #FFF;
              color: #002855;
              text-decoration: none;
              font-size: 18px;
              padding: 10px 45px;
              display: inline-block;
              border: solid #002855 1px;
              border-radius: 3px;
              font-family: Josefin Sans, Helvetica, sans-serif;
            ">ARMFOU2682</a>
      </p>
      <div style="margin: 40px auto; background: #002855; padding: 15px 60px 0 60px;">
        <p style="
        color: #FFF;
        font-weight: bold;
        margin-bottom: 0px;
        font-size: 20px;
        font-family: Josefin Sans, Helvetica, sans-serif;
      ">
          Gagnez au moins 500€ supplémentaires
        </p>
        <p style="color: #FFF; margin-top: 15px; font-size: 13px;">
          pour tout filleul parrainé qui apporte un contact pour lequel
          l’affaire est conclue. Plus vous avez de filleuls et de petits
          filleuls, plus vous augmentez vos revenus.
        </p>
        <p style="color: #FFF; font-size: 13px;">À bientôt,</p>
        <p style="color: #FFF; font-weight: bold; font-size: 13px; font-family: Josefin Sans;">L’équipe Saint-Clair !
        </p>
        <img style="width: 90px"
          src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20blanc%201.svg?alt=media&token=797ea472-2554-415c-af89-de1faddc5320&_gl=1*zdeufq*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIzNDkxOC4yNi4xLjE2OTgyMzQ5MjAuNTguMC4w"
          alt="logo" />
      </div>
    </div>
</body>

</html>
    `;
  }

  // email imsf
  // email imsf
  // email imsf
  // email imsf
  // email imsf
  // email imsf
  // email imsf
  // email imsf
  // email imsf
  // email imsf
  // email imsf

  respoSignupForRespo(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Vous êtes inscrit sur Saint-Clair</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin-bottom: 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
              ">
            Félicitations !
          </p>
          <p style="color: #FFF; margin-top: 10px">
            Votre compte ${data.respo.firstName} ${data.respo.lastName} de ${data.company.name} a été créé sur Saint-Clair.
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
              Vous pouvez désormais ajouter des collaborateurs <span style="color: #B9A784;">afin qu'ils déposent leurs premières affaires.</span>
            </p>
            <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                  background-color: #002855;
                  color: #ffffff;
                  text-decoration: none;
                  font-size: 22px;
                  padding: 10px 20px;
                  display: inline-block;
                  border-radius: 3px;
                  font-family: Josefin Sans, Helvetica, sans-serif;
                  margin: 15px 0 20px 0;
                ">Se connecter</a>
          </div>
    
          <div style="margin: 40px auto; background: #002855; padding: 15px 60px 0 60px;">
            <p style="
            color: #FFF;
            font-weight: bold;
            margin-bottom: 0px;
            font-size: 20px;
            font-family: Josefin Sans, Helvetica, sans-serif;
          ">
              Pour rappel
            </p>
            <p style="color: #FFF; margin-top: 15px; font-size: 13px;">
                Pour rappel, chaque affaire « professionnelle » peut rapporter <span style="color: #FFF; font-size: 13px;">1500€ à votre entreprise</span> si la vente s’effectue et 
                <span style="color: #FFF; font-size: 13px;">500€ au collaborateur</span>. </br> Et chaque affaire « personnelle » peut rapporter 
                <span style="color: #FFF; font-size: 13px;">500€ à votre entreprise</span> si la 
                vente s’effectue et <span style="color: #FFF; font-size: 13px;">1500€ au collaborateur</span>.
            </p>
            <p style="color: #FFF; font-size: 13px;">À bientôt,</p>
            <p style="color: #FFF; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
              src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20blanc%201.svg?alt=media&token=797ea472-2554-415c-af89-de1faddc5320&_gl=1*zdeufq*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIzNDkxOC4yNi4xLjE2OTgyMzQ5MjAuNTguMC4w"
              alt="logo" />
          </div>
    
        </div>
      </div>
    </body>
    
    </html>`;
  }

  colabSignupForColab() {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Votre accès à Saint-Clair est prêt !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin-bottom: 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
              ">
            Félicitations !
          </p>
          <p style="font-size: 16px; color: #FFF; margin-top: 10px">
            Vous êtes officiellement embassadeur Saint-Clair !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
              Vous pouvez désormais ajouter des collaborateurs <span style="color: #B9A784;">afin qu'ils déposent leurs premières affaires.</span>
            </p>
            <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                  background-color: #002855;
                  color: #ffffff;
                  text-decoration: none;
                  font-size: 22px;
                  padding: 10px 20px;
                  display: inline-block;
                  border-radius: 3px;
                  font-family: Josefin Sans, Helvetica, sans-serif;
                  margin: 15px 0 20px 0;
                ">Se connecter</a>
          </div>
    
            <p style="
            color: #002855;
            font-weight: bold;
            margin-bottom: 0px;
            font-size: 20px;
            font-family: Josefin Sans, Helvetica, sans-serif;
          ">
              Pour rappel
            </p>
            <p style="color: #002855; margin-top: 15px; font-size: 13px;">
                Chaque affaire « professionnelle » peut vous rapporter <b style="color: #002855; font-size: 13px;">500€</b> si la vente s'effectue.
            </br>
                Chaque affaire « personnelle » peut vous rapporter <b style="color: #002855; font-size: 13px;">1500€</b> si la vente s'effectue.
            </p>
            <p style="color: #002855; font-size: 13px;">À bientôt,</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }

  colabSignupForAdmin(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Un nouveau collaborateur a été enregistré</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
          <p style="
                color: #FFF;
                font-size: 32px;
                margin-bottom: 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
              ">
            Un nouveau collaborateur a été enregistré !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;">
              Bonjour, Un nouveau collaborateur a été ajouté par <span style="color: #B9A784;">${
                data.respo.lastName
              } de ${data.company.name} le ${new Date(
      Date.now().toLocaleString()
    )}</span>
            </p>
            <div style="margin: 40px auto; font-size: 13px;">
                <b>Les informations de ce nouvel ambassadeur : </b><br /><br />
                NOM: ${data.colab.lastName}<br />
                PRENOM: ${data.colab.firstName}<br />
                EMAIL: ${data.colab.email}<br />
                TELEPHONE: ${data.colab.phoneNumber}
          </div>
    
          <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
          background-color: #002855;
          color: #ffffff;
          text-decoration: none;
          font-size: 18px;
          padding: 10px 20px;
          display: inline-block;
          border-radius: 3px;
          font-family: Josefin Sans, Helvetica, sans-serif;
          margin: 15px 0 20px 0;
        ">Consulter sa fiche</a>
    </div>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }
  // !
  apafValidatedForColab() {
    return `<!DOCTYPE html>
    <html lang="fr">

<head>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
    rel="stylesheet">
  <title>Votre contrat d’apporteur d’affaires est signé !</title>
</head>

<body>
  <div style="
        margin: 20px auto;
        width: 600px;
        text-align: center;
        font-family: Nunito sans, Helvetica, sans-serif;
      ">
    <div style="margin: 20px auto 40px auto">
      <img style="width: 150px"
        src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
        alt="logo" />
    </div>
    <div style="background-color: #B9A784; padding: 20px; ">
      <p style="color: #FFF; font-size: 32px; font-family: Josefin Sans;">
        Félicitations !
      </p>
      <p style="color: #FFF;">
        votre contrat d’apporteur d’affaires est signé !
      </p>
    </div>
    <div style="margin: 40px auto">
      <p style="color: #002855; font-weight: bold; font-size: 20px">
        Déposez votre première affaire et <span style="color: #B9A784;">gagnez 1500€.</span>
      </p>
      <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
          background-color: #002855;
          color: #ffffff;
          text-decoration: none;
          font-size: 22px;
          padding: 10px 20px;
          display: inline-block;
          border-radius: 3px;
          font-family: Josefin Sans;
          margin: 30px 0 30px 0;
        ">Déposer votre affaire</a>
      <p style="color: #002855">
        Votre code parrain :
        <!-- <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
        //       background-color: #FFF;
        //       color: #002855;
        //       text-decoration: none;
        //       font-size: 18px;
        //       padding: 10px 45px;
        //       display: inline-block;
        //       border: solid #002855 1px;
        //       border-radius: 3px;
        //       font-family: Josefin Sans, Helvetica, sans-serif;
        //     ">ARMFOU2682</a> -->
      </p>
      <div style="margin: 40px auto; background: #002855; padding: 15px 60px 0 60px;">
        <p style="
        color: #FFF;
        font-weight: bold;
        margin-bottom: 0px;
        font-size: 20px;
        font-family: Josefin Sans, Helvetica, sans-serif;
      ">
          Gagnez au moins 500€ supplémentaires
        </p>
        <p style="color: #FFF; margin-top: 15px; font-size: 13px;">
          pour tout filleul parrainé qui apporte un contact pour lequel
          l’affaire est conclue. Plus vous avez de filleuls et de petits
          filleuls, plus vous augmentez vos revenus.
        </p>
        <p style="color: #FFF; font-size: 13px;">À bientôt,</p>
        <p style="color: #FFF; font-weight: bold; font-size: 13px; font-family: Josefin Sans;">L’équipe Saint-Clair !
        </p>
        <img style="width: 90px"
          src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20blanc%201.svg?alt=media&token=797ea472-2554-415c-af89-de1faddc5320&_gl=1*zdeufq*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIzNDkxOC4yNi4xLjE2OTgyMzQ5MjAuNTguMC4w"
          alt="logo" />
      </div>
    </div>
</body>

</html>`;
  }
  // !
  businessStatusChangeForColab(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
        <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Le statut de votre affaire à changé !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
                text-wrap: balance;
              ">
              Saint-Clair a changé le statut de votre affaire concernant le contact</br> ${data.prospect.firstName} ${data.prospect.lastName}
            </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance; line-height: 150%;">
              Consultez l'état d'avancée de votre affaire directement <span style="color: #B9A784;">sur votre Espace Collaborateur :</span>
            </p>
            <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                  background-color: #002855;
                  color: #ffffff;
                  text-decoration: none;
                  font-size: 22px;
                  padding: 10px 20px;
                  display: inline-block;
                  border-radius: 3px;
                  font-family: Josefin Sans, Helvetica, sans-serif;
                  margin: 15px 0 20px 0;
                ">Espace Collaborateur</a>
          </div>
    
            <p style="color: #002855; font-size: 13px;">À bientôt,</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }

  businessClosedForColab() {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
        <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Votre affaire est conclue !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
                text-wrap: balance;
              ">
            Votre affaire est conclue !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance; line-height: 150%;">
              Grâce à votre affaire, vous remportez </br><span style="color: #B9A784;">500€ (si affaire pro) / 1500€(si affaire perso) !</span>
            </p>
            <small>Rendez-vous sur votre espace ambassadeur pour déposez une nouvelle affaire.</small>
            <a href="https://ambassadeurs.saintclair-patrimoine.fr/" style="
                  background-color: #002855;
                  color: #ffffff;
                  text-decoration: none;
                  font-size: 22px;
                  padding: 10px 20px;
                  display: inline-block;
                  border-radius: 3px;
                  font-family: Josefin Sans, Helvetica, sans-serif;
                  margin: 15px 0 20px 0;
                ">Espace Collaborateur</a>
          </div>
    
            <p style="color: #002855; font-size: 13px;">À bientôt,</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>
    `;
  }

  businessClosedForRespo(data) {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
        <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Une affaire est conclue !</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
                text-wrap: balance;
              ">
            Une affaire déposée par
            ${data.colab.firstName} ${data.colab.lastName}
            est conclue !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance; line-height: 150%;">
              Grâce à votre affaire, vous remportez </br><span style="color: #B9A784;">500€ (si affaire pro) / 1500€(si affaire perso) !</span>
            </p>
          </div>
    
            <p style="color: #002855; font-size: 13px;">À bientôt,</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }

  proToPartForColab() {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
        <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Changement de votre affaire effectué</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
                text-wrap: balance;
                line-height: 150%;
              ">
            Le changement de votre affaire en
            "Particulier" a bien été enregistré !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance; line-height: 150%;">
              Si l'affaire est conclue, <span style="color: #B9A784;">vous gagnerez désormais 1500€ !</span>
            </p>
            <small style="text-wrap: balance;">N'hésitez pas à consulter régulièrement votre compte Saint-Clair</br>
              afin de suivre l'avancée de votre affaire.</small>
          </div>
    
            <p style="color: #002855; font-size: 13px;">Cordialement.</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }

  partToProForColab() {
    return `<!DOCTYPE html>
    <html lang="fr">
    
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link
        href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
        <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&display=swap"
        rel="stylesheet">
      <title>Changement de votre affaire effectué</title>
    </head>
    
    <body>
      <div style="
            margin: 20px auto;
            text-align: center;
            font-family: Nunito Sans, Helvetica, sans-serif;
            width: 600px;
          ">
        <div style="margin: 50px auto 40px auto">
          <img style="width: 150px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FLogo%20Saint-Clair%20full%20RVB-bleu%20fonce%CC%81%201.svg?alt=media&token=a0415831-3a26-41bf-b7d3-301595676812&_gl=1*x3gis8*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODIyNjM0NS4yNS4xLjE2OTgyMjY3NjcuMTQuMC4w"
            alt="logo" />
        </div>
        <div style="background-color: #B9A784; padding: 15px 0 20px 0">
    
          <p style="
                color: #FFF;
                font-size: 32px;
                margin: 16px 0px;
                font-family: Josefin Sans, Helvetica, sans-serif;
                text-wrap: balance;
                line-height: 150%;
              ">
            Le changement de votre affaire en
            "Professionnel" a bien été enregistré !
          </p>
        </div>
        <div style="margin: 40px auto; text-align: center;">
          <div style="padding: 15px;">
            <p style="color: #002855; font-weight: bold; font-size: 20px;font-family: Josefin Sans, Helvetica, sans-serif;text-wrap: balance; line-height: 150%;">
              Si l'affaire est conclue, <span style="color: #B9A784;">vous gagnerez désormais 500€ !</span>
            </p>
            <small style="text-wrap: balance;">N'hésitez pas à consulter régulièrement votre compte Saint-Clair</br>
              afin de suivre l'avancée de votre affaire.</small>
          </div>
    
            <p style="color: #002855; font-size: 13px;">Cordialement.</p>
            <p style="color: #002855; font-weight: bold; font-size: 13px;">L’équipe Saint-Clair !</p>
            <img style="width: 90px"
            src="https://firebasestorage.googleapis.com/v0/b/immosansfrais.appspot.com/o/emails%2FIcone%20Saint-Clair%20Bleu%20fonce%CC%81%201.svg?alt=media&token=1f98eeee-a2e0-4511-b749-6920845152f6&_gl=1*1umn5cw*_ga*MTA3Mjk2NjE1MS4xNjgxNzMwNDc0*_ga_CW55HF8NVT*MTY5ODI0MjI2NS4yOC4xLjE2OTgyNDIyNjYuNTkuMC4w"  
            alt="logo" />
    
        </div>
      </div>
    </body>
    
    </html>`;
  }
}
