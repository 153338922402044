import { CookieComponent } from "./cookie/cookie.component";
import { FooterComponent } from "./footer/footer.component";
import { LoggedMenuComponent } from "./logged-menu/logged-menu.component";
import { NotLoggedMenuComponent } from "./not-logged-menu/not-logged-menu.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SponsorshipComponent } from "./sponsorship/sponsorship.component";

export * from "./not-logged-menu/not-logged-menu.component";
export * from "./cookie/cookie.component";
export * from "./logged-menu/logged-menu.component";
export * from "./sponsorship/sponsorship.component";
export * from "./sidebar/sidebar.component";

export const components = [
    NotLoggedMenuComponent,
    FooterComponent,
    CookieComponent,
    LoggedMenuComponent,
    SponsorshipComponent,
    SidebarComponent
]
