import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class GodchildrenService {
  godchildrenCollection;

  constructor(
    private readonly afStore: AngularFirestore,
  ) {
    this.godchildrenCollection = this.afStore.collection<any>('Godchildren');
  }
}
