import { ForgotPasswordPageComponent } from "./forgot-password-page/forgot-password-page.component";
import { LoginPageComponent } from "./login-page/login-page.component";
import { RegisterPageComponent } from "./register-page/register-page.component";

export * from "./login-page/login-page.component";
export * from "./register-page/register-page.component";
export * from "./forgot-password-page/forgot-password-page.component";

export const pages = [
    LoginPageComponent,
    RegisterPageComponent,
    ForgotPasswordPageComponent
]