import { CollectionsService } from './collections.service';
import { EmailTemplatesService } from './email-templates.service';
import { GodchildrenService } from './godchildren.service';
import { UserService } from './user.service';

export * from './user.service';
export * from './godchildren.service';
export * from './collections.service';
export * from './email-templates.service';

export const services = [
    UserService,
    GodchildrenService,
    CollectionsService,
    EmailTemplatesService
];
