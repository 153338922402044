import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services';
import { UserService } from 'src/app/shared/services';

@Injectable()
export class CompanyGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private _snackBar: MatSnackBar,
) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.user$.pipe(
      take(1),
      map((user) => !(user.data?.companyId)),
      tap((isCompany) => {
        if (!isCompany) {
          this._snackBar.open('Vous ne pouvez pas acceder à ce compte', null, {
            duration: 3000,
          });
          this.userService.resetUser();
        }
      }),
  );
  }
}
