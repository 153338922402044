import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { User } from "../../interfaces/AuthUser";
import { CollectionsService, EmailTemplatesService } from "../../services";

@Component({
  selector: "app-sponsorship",
  templateUrl: "./sponsorship.component.html",
  styleUrls: [
    "./sponsorship.component.scss",
    "../../assets/styles/styles.scss",
  ],
})
export class SponsorshipComponent implements OnInit {
  @Input() userData: User;

  referralCode: string;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private collectionsService: CollectionsService,
    private emailTemplatesService: EmailTemplatesService
  ) {
    this.form = this.formBuilder.group({
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  ngOnInit() {
    this.referralCode = this.userData.referralCode;
  }

  async submit() {
    const url = `https://ambassadeurs.saintclair-patrimoine.fr/auth/register?rf=${this.userData?.referralCode}`;

    const emailBody = {
      to: this.form.get("email").value,
      subject: `${this.userData.firstName} ${this.userData.lastName} souhaite vous parrainer à Saint-Clair!`,
      content: this.emailTemplatesService.referralCode(url, this.userData),
    };
    console.log(emailBody);

    console.log(
      await this.collectionsService.postDocumentCollection("Emails", emailBody)
    );

    this._snackBar.open("Email envoyé !", null, {
      duration: 3000,
    });

    this.form.reset();
    this.form.controls.email.setErrors(null);
  }

  copy(label: string) {
    this._snackBar.open(label + " copié", null, {
      duration: 3000,
    });
  }
}
