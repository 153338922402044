import { GodchildrenComponent } from "./godchildren/godchildren.component";
import { ParametersComponent } from "./parameters/parameters.component";
import { BusinessComponent } from "./business/business.component";
import { ProfilComponent } from "./profil/profil.component";
import { CreateBusinessComponent } from "./create-business/create-business.component";
import { CharterProtectionComponent } from "./charter-protection/charter-protection.component";
import { ContactPageComponent } from "./contact-page/contact-page.component";

export * from "./profil/profil.component";
export * from "./create-business/create-business.component";
export * from "./charter-protection/charter-protection.component";
export * from "./godchildren/godchildren.component";
export * from "./parameters/parameters.component";
export * from "./business/business.component";
export * from "./contact-page/contact-page.component";

export const pages = [
    ProfilComponent,
    BusinessComponent,
    GodchildrenComponent,
    ParametersComponent,
    CreateBusinessComponent,
    CharterProtectionComponent,
    ContactPageComponent
]