import { NgModule } from '@angular/core';
import { ClientComponent } from './client.component';
import { ClientRoutingModule } from './client-routing.module';
import { SharedModule } from '../shared/shared.module';
import { pages } from './pages';
import { GeoapifyGeocoderAutocompleteModule } from '@geoapify/angular-geocoder-autocomplete';

@NgModule({
  imports: [
    ClientRoutingModule,
    SharedModule,
    GeoapifyGeocoderAutocompleteModule.withConfig('d8890bb383c646a89f22914ac1d833dd'),
  ],
  declarations: [ClientComponent, ...pages]
})
export class ClientModule { }
