import { ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { AuthService } from '../auth/services';
import { User } from '../shared/interfaces/AuthUser';
import { UserService } from '../shared/services';

interface Link {
  path: string;
  icon: string;
  label: string;
}

@Component({
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})


export class ClientComponent {
  switchAccountType = false;
  user?: any;
  userData?: User;

  /*
        <a
        routerLinkActive="active-link"
        routerLink="/client/profil"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <img class="picto" src="../../assets/picto/picto-espace-perso-profil-{{ router.isActive('/client/profil', true) ? 'bleu' : 'gris'}}.png">
        <span>Profil</span>
      </a>

      <a
        routerLinkActive="active-link"
        routerLink="/client/business"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <img class="picto" src="../../assets/picto/picto-espace-perso-affaires-{{ router.isActive('/client/business', true) ? 'bleu' : 'gris'}}.png">
        <span>Affaires</span>
      </a>

      <a
        routerLinkActive="active-link"
        routerLink="/client/godchildren"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <img class="picto" src="../../assets/picto/picto-espace-perso-filleuls-{{ router.isActive('/client/godchildren', true) ? 'bleu' : 'gris'}}.png">
        <span>Filleuls</span>
      </a>

      <a
        routerLinkActive="active-link"
        routerLink="/client/parameters"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <img class="picto" src="../../assets/picto/picto-espace-perso-parametres-{{ router.isActive('/client/parameters', true) ? 'bleu' : 'gris'}}.png">
        <span>Paramètres</span>
      </a>

      <a
        routerLinkActive="active-link"
        routerLink="/client/contact"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <img class="picto" src="../../assets/picto/picto-contact-{{ router.isActive('/client/contact', true) ? 'bleu' : 'gris'}}.png">
        <span>Contact</span>
      </a>

      <a
        routerLinkActive="active-link"
        routerLink="/client/charter-protection"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <img class="picto" src="../../assets/picto/picto-charte-de-donnee-{{ router.isActive('/client/charter-protection', true) ? 'bleu' : 'gris'}}.png">
        <span>Charte des données personnelles</span>
      </a>
      <a
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        (click)="logout()"
        class="btn-logout">
        <mat-icon>logout</mat-icon>
        <span>Déconnexion</span>
      </a>

  
  */

  links: Link[] = [
    { path: '/client/profil', icon: 'home', label: 'Profil' },
    { path: '/client/business', icon: 'business', label: 'Affaires' },
    { path: '/client/godchildren', icon: 'users', label: 'Filleuls' },
    { path: '/client/parameters', icon: 'cog', label: 'Paramètres' },
    { path: '/client/contact', icon: 'envelope', label: 'Contact' },
    { path: '/client/charter-protection', icon: 'text-document', label: 'Charte des données personnelles' },
  ];


  constructor(
    public router: Router,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private storage: LocalStorageService,
    private authService: AuthService,
  ) {
    this.getUser();
  }

  getUser() {
    this.userService.user$.subscribe((user) => {
      this.user = user;
      this.userData = user.data;
      this.cdr.markForCheck();
    }, () => {
      this._snackBar.open('Une erreur est survenue', null, {
        duration: 3000,
      });
    });
  }

  changeAccountType() {
    this.switchAccountType = true;
    this.storage.set('admin', true);
    setTimeout(() => {
      this.router.navigate(['/admin']);
    }, 2000);
  }

  logout() {
    this.userService.resetUser();
  }
}
